/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


*, body {
    font-family: 'Poppins', sans-serif;    
}

/* Ocultar la barra de desplazamiento en navegadores webkit (Chrome, Safari) */
.overflow-x-auto::-webkit-scrollbar {
    display: block;
    border-radius: 5px;
    width: 1px;
    height: 5px;              
  }

  .overflow-x-auto::-webkit-scrollbar-thumb {
    background-color: #eb6d06;
  }

  ::-webkit-scrollbar{
    display: none;
    color: red;
  }
  
  /* Ocultar la barra de desplazamiento en navegadores Internet Explorer y Edge */
  body {
    -ms-overflow-style: none;
  }